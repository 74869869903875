import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import css from "./Login.module.css";
import { UserContext } from "../../Context/UserContext";
import { LoginForm } from "./LoginForm";
import LoginPasswordLost from "./LoginPasswordLost.js";
import { NotFound } from "../NotFound";

export const Login = () => {
  const { user } = React.useContext(UserContext);

  if (user) return <Navigate to="/" />;

  return (
    <section className={css.login}>
      <div></div>
      <div className={css.forms}>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="recover" element={<LoginPasswordLost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <div></div>
    </section>
  );
};
