import React from "react";
import css from "./Input.module.css";

export const InputArea = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  onBlur,
  required,
  maxLength,
  placeholder,
}) => {
  return (
    <div className={css.wrapper}>
      <label htmlFor={name} className={css.label}>
        {label}
      </label>
      {required ? (
        <textarea
          className={css.textarea}
          rows="20"
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete="off"
          required
        />
      ) : (
        <textarea
          className={css.textarea}
          rows="20"
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete="off"
        />
      )}

      {error && <p className={css.error}>{error}</p>}
    </div>
  );
};
